import React, { useRef, useEffect, useState } from "react";
import "./CurrentPlaceName.css";

const CurrentPlaceName = ({ locality, region, totalKm, totalAscent }) => {
  const [currentPlaceName, setCurrentPlaceName] = useState("...");
  const [currentTotalKm, setCurrentTotalKm] = useState(0);
  const [currentTotalMi, setCurrentTotalMi] = useState(0);
  const [currentTotalAscent, setCurrentTotalAscent] = useState(0);

  useEffect(() => {
    if (region != "") {
      setCurrentPlaceName(locality + ", " + region);
    }
    setCurrentTotalKm(totalKm);
    setCurrentTotalMi(Math.round(((totalKm * 1000) / 1610) * 10) / 10);
    setCurrentTotalAscent(totalAscent);
  }, [locality, region, totalKm]);

  function handlePlaceNameClick() {
    alert(
      "Total Distance:" +
        "\n" +
        currentTotalKm.toString() +
        " km" +
        "\n" +
        currentTotalMi.toString() +
        " mi" +
        "\n\n" +
        "Total Ascent:" +
        "\n" +
        currentTotalAscent.toString() +
        " m"
    );
  }

  return (
    <div>
      <h2 id="currentPlaceNameText" onClick={handlePlaceNameClick}>
        {currentPlaceName}
      </h2>
    </div>
  );
};

export { CurrentPlaceName };
